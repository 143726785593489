
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function dialogButtonMobileRT () {
    return _createElement('div', { 'className': 'cm_search-box-root__dialog_open-button' }, [this.searchBoxDialogButton(function () {
            return _createElement('div', { 'className': 'cm_desktop-hide  cm_searchBoxDialogButton' }, _createElement('svg', {
                'version': '1.1',
                'xmlns': 'http://www.w3.org/2000/svg',
                'width': '20',
                'height': '20',
                'viewBox': '0 0 61 64'
            }, _createElement('path', { 'd': 'M16.64 2.24c5.888-2.56 12.672-2.88 18.816-0.96 6.016 1.92 11.2 6.080 14.592 11.392 3.648 5.568 5.12 12.48 4.224 19.072-0.64 5.312-2.944 10.368-6.336 14.464 4.288 4.416 8.64 8.768 12.864 13.184-1.408 1.536-3.072 2.944-4.288 4.608h-0.32c-0.064-0.448-0.576-0.64-0.768-0.96-4.096-4.16-8.128-8.32-12.16-12.416-4.672 3.456-10.496 5.376-16.384 5.312-6.080-0.064-12.096-2.368-16.768-6.208-6.272-5.184-9.984-13.184-10.112-21.312v-0.832c0.064-4.736 1.344-9.536 3.712-13.632 2.88-5.184 7.488-9.408 12.928-11.712M26.048 3.328c-8.448 0.384-16.32 5.696-20.096 13.184-2.816 5.504-3.456 12.096-1.856 18.112 1.472 5.44 4.864 10.304 9.472 13.632 3.84 2.752 8.576 4.288 13.312 4.352 5.056 0.128 10.112-1.472 14.208-4.48 3.776-2.688 6.72-6.464 8.384-10.752 2.432-5.888 2.432-12.8 0.064-18.752-1.92-4.864-5.504-9.088-9.984-11.84-4.032-2.496-8.832-3.712-13.504-3.456z' })));
        }, {
            widgetName: 'undefined',
            items: undefined
        })]);
}
        export const componentNames = ["cm:searchBoxDialogButton"]