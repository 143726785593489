import shopifyGenericDefaults from '../shopify-generic/config.js';

export default {
  includes: ['shopify-generic'],
  ...shopifyGenericDefaults,
  SearchRequestDefaults: {
    pageSize: 36,
    sort: 'relevance',
    extra: {
      ...shopifyGenericDefaults.SearchRequestDefaults.extra,
      isLocationListPage: !!window.document.querySelector('#cm-speaker-location-list'),
    },
  },
  fitmentSearch: {
    ...shopifyGenericDefaults.fitmentSearch,
    categorySelectionPages: [
      ...shopifyGenericDefaults.fitmentSearch.categorySelectionPages,
      { pathname: '/pages/shop-by-speaker-location', field: 'Location' },
    ],
  },
  Widgets: [
    ...shopifyGenericDefaults.Widgets.filter(
      (w) => !['FacetBrandTiles', 'HeaderVehicleWidget'].includes(w.name),
    ),
    {
      name: 'HeaderVehicleWidget',
      type: 'VehicleWidget',
      location: '#cm-ymm',
      template: 'fitmentSearch/collapsibleVehicleWidget',
      columnBreakpoint: 650,
      redirectUrl: '/pages/shop-by-speaker-location',
      initCollapsed: true,
    },
    {
      name: 'FacetBrandTiles',
      type: 'FacetTiles',
      location: '#cm-brand-list',
      template: 'facetTiles/hierarchicalContainer',
      view: 'grid',
      isVehicleWidgetDisabled: !!window.document.querySelector('#cm-ymm'),
    },
    {
      name: 'FacetLocationTiles',
      type: 'FacetTiles',
      location: '#cm-speaker-location-list',
      template: 'facetTiles/locationContainer',
      view: 'grid',
      isVehicleWidgetDisabled: !!window.document.querySelector('#cm-ymm'),
    },
  ],
};
