
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function relatedItemsRT () {
    function repeatItems1(items, itemsIndex) {
        return [items(function () {
                return _createElement('div', { 'className': 'product-product-grid' }, _createElement('product-card', {}, _createElement('section', { 'className': 'product-card balance-row-1 effect-overlay' }, _createElement('header', { 'className': 'product-card_header' }, _createElement('div', { 'className': 'product-card_vendor-title' }, _createElement('h4', { 'className': 'product-vendor' }, _createElement('a', Object.assign({}, { 'href': this.vendor_url }, { dangerouslySetInnerHTML: { __html: this.vendor } }))), _createElement('h3', { 'className': 'product-card_title' }, _createElement('a', Object.assign({}, { 'href': this.url }, { dangerouslySetInnerHTML: { __html: this.title } })))), _createElement('div', { 'className': 'product-card_media position-relative' }, _createElement('a', { 'href': this.url }, _createElement('span', {
                    'className': 'image__style position-relative',
                    'style': { paddingTop: '56.25%' }
                }, _createElement('img', {
                    'className': 'overlay-ui w-100 h-100 lazyautosizes lazyloaded',
                    'src': this.imageOrDefault(this.resizeImage(this.image)),
                    'alt': this.removeHTML(this.title),
                    'onError': this.onImageError
                }), _createElement('noscript', {}, this.image2 ? _createElement('img', {
                    'className': 'pa w100 h100 l0 t0',
                    'src': this.resizeImage(this.image2),
                    'alt': this.removeHTML(this.title),
                    'onError': e => this.onImageError(e, 'image2'),
                    'key': '927'
                }) : null))))), _createElement('footer', { 'className': 'product-card_footer' }, _createElement('div', { 'className': 'product-card_price_action' }, _createElement('div', { 'className': 'product-price' }, this.on_sale ? _createElement('div', {
                    'className': 'price-sale',
                    'key': '1349'
                }, this.formatPrice(this.compare_at_price)) : null, this.on_sale ? _createElement('del', {
                    'className': 'price-compare',
                    'key': '1454'
                }, this.formatPrice(this.price)) : null, !this.on_sale ? _createElement('div', {
                    'className': 'price',
                    'key': '1551'
                }, this.formatPrice(this.price)) : null), _createElement('div', { 'className': 'product-card_actions' }, _createElement('form', {
                    'method': 'post',
                    'action': '/cart/add',
                    'acceptCharset': 'UTF-8',
                    'className': 'product-form js-form-add-to-cart',
                    'encType': 'multipart/form-data',
                    'noValidate': 'novalidate'
                }, _createElement('input', {
                    'type': 'hidden',
                    'name': 'form_type',
                    'value': 'product'
                }), _createElement('input', {
                    'type': 'hidden',
                    'name': 'utf8',
                    'value': '\u2713'
                }), _createElement('input', {
                    'type': 'hidden',
                    'name': 'id',
                    'value': this.variant_ids[0]
                }), _createElement('button', {
                    'type': 'submit',
                    'name': 'add',
                    'className': 'btn-add-to-cart js-atc-btn'
                }, _createElement('svg', {
                    'version': '1.1',
                    'xmlns': 'http://www.w3.org/2000/svg',
                    'width': '18',
                    'height': '18',
                    'viewBox': '0 0 68 64'
                }, _createElement('path', { 'd': 'M2.24 0.576c3.52-0.704 7.168 0.192 10.24 1.792 2.624 1.344 4.224 4.096 5.056 6.848 0.96 3.008 1.344 6.144 1.92 9.216 0.96 5.056 2.304 10.048 3.072 15.104 0.384 2.048 0.704 4.224 2.112 5.888 1.408 1.6 3.776 1.664 5.824 1.728 5.376 0 10.816-0.256 16.192 0.128 1.344 0.064 2.88 0 3.648-1.344 3.52-5.312 6.464-11.008 9.28-16.768 0.576-1.152 1.28-2.368 2.56-2.816 1.344-0.448 3.008-0.256 4.032 0.768 1.152 1.28 1.344 3.2 0.704 4.736-2.688 5.376-5.184 10.816-8.512 15.872-1.472 2.176-3.008 4.544-5.184 6.144-1.792 1.28-4.096 0.832-6.144 0.768-6.272-0.064-12.544 0.384-18.752 0-4.16-0.32-8.256-2.368-10.624-5.888-1.728-2.752-2.368-6.080-3.008-9.216-1.28-6.784-2.624-13.504-3.904-20.288-0.384-1.6-0.896-3.392-2.304-4.352-2.24-1.6-5.376-0.256-7.552-1.984-1.344-0.96-0.704-2.688-0.832-4.096-0.128-1.216 1.152-1.984 2.176-2.24zM34.432 4.928c-0.064-1.216 1.408-1.536 2.368-1.792 2.048-0.32 4.608-0.384 6.208 1.152 0.448 3.776 0.64 7.616 0.768 11.392 1.92 0.128 3.84-0.256 5.76-0.064 0.704 0.064 1.536 0.512 1.472 1.344-0.32 1.472-1.472 2.56-2.368 3.712-2.688 3.456-5.76 6.528-8.832 9.536-0.448 0.448-1.024 0.704-1.6 0.448-1.216-0.512-1.984-1.728-2.88-2.688-2.112-2.304-4.224-4.544-6.144-6.912-0.96-1.216-2.176-2.24-2.624-3.712-0.256-0.832 0.512-1.6 1.344-1.664 1.92-0.256 3.84 0.128 5.76 0 0.256-3.584 0.384-7.168 0.768-10.752zM25.28 51.584c2.432-0.704 5.184 0 6.784 1.856 2.368 2.624 1.984 7.232-0.96 9.216-3.136 2.304-8.448 1.216-9.92-2.56-1.664-3.328 0.64-7.616 4.096-8.512zM45.376 53.504c2.432-2.88 7.36-2.944 9.856-0.128 2.112 2.304 2.048 6.208-0.064 8.512-1.216 1.28-2.944 2.048-4.672 2.112h-0.128c-1.856-0.064-3.776-0.832-4.928-2.24-2.048-2.304-2.112-6.016-0.064-8.256z' })), _createElement('span', { 'className': 'text' }, 'Add To Cart'))))), _createElement('div', { 'className': 'product-buttons' }, _createElement('div', { 'className': 'product-wishlist' }, _createElement('a', {
                    'data-arn-action': 'add',
                    'data-handle': this.handle,
                    'className': 'add-to-wishlist icon-1',
                    'href': 'javascript:;'
                }, _createElement('svg', {
                    'width': '18',
                    'height': '16',
                    'className': 'arn_icon arn_icon-add-wishlist'
                }, _createElement('use', { 'xlinkHref': '#arn_icon-add-wishlist' })), _createElement('svg', {
                    'width': '18',
                    'height': '16',
                    'className': 'arn_icon arn_icon-preloader'
                }, _createElement('use', { 'xlinkHref': '#arn_icon-preloader' })), _createElement('span', {}, 'Wishlist'))))))));
            }, { count: undefined })];
    }
    return _createElement('div', { 'className': 'cm_related-products' }, _createElement('div', { 'className': 'cm_related-products-title-container' }, _createElement('h2', { 'className': 'cm_related-products-title' }, 'You might also like...')), _createElement.apply(this, [
        'div',
        { 'className': 'cm_related-products-list cmRepeater_items' },
        _map(this.items, repeatItems1.bind(this))
    ]));
}
        export const componentNames = []