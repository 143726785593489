export default {
  VEHICLE_CATEGORY_WRAPPER_CLASS: 'collection-card',

  SHOW_LESS: '- Show less',
  SHOW_MORE: '+ Show more',

  SEARCH_PAGE_CLASS: 'page-collection-template',

  GARAGE_SIZE: '<span class="garage-size number position-absolute">{{size}}</span>',
};
